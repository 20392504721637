import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Card, Row, Col } from 'antd';
import axios from 'axios';
import AppLayout from '../components/layout/AppLayout'; // Import AppLayout
import Main from '../components/layout/Main';

export default function MyAccount() {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('jwtToken');
   // const [setChargebeeReady] = useState(false);


    const [showPasswordFields, setShowPasswordFields] = useState(false);
    const formItemStyle = { width: '100%' };
    const inputStyle = { height: '50px' };
    const [isRootUser, setIsRootUser] = useState(false); // Add state for root user status

  
    useEffect(() => {
      const script = document.querySelector('script[src*="chargebee.js"]');
      if (script) {
          script.addEventListener('load', () => {
              //setChargebeeReady(true);
          });
      }
  }, []);

    useEffect(() => {
      // Fetch user data and set form values
      const fetchUserData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/user/`, {
                headers: {
                   Authorization: `Bearer ${token}`,
                },
            });

            form.setFieldsValue(response.data);
            setIsRootUser(response.data.root || false); // Set root user status
        } catch (error) {
          message.error('Error fetching user data');
        }
      };
  
      fetchUserData();
    }, [form, token]);
   
    const handleSubmit = async (values: any) => {
      setLoading(true);
      try {
        await axios.put(`${process.env.REACT_APP_API_DOMAIN}/user/`, {values},{
            headers: {
               Authorization: `Bearer ${token}`,
            },
        });
        message.success('Profile updated successfully');
        form.resetFields(['password','confirmPassword']); // Clear the password field
        setShowPasswordFields(false);
      } catch (error) {
        message.error('Error updating profile');
      } finally {
        setLoading(false);
      }
    };

    const handleManageAccount = () => {
      // Logic to handle account management
      console.log('Manage account clicked');
    };
  
    return (
      <>
        <AppLayout>
          <Main>
            <Card
              bordered={false}
              className="header-solid mb-16"
              title={
                <>
                  <h6 className="font-semibold">Account Update</h6>
                </>
              }
            >
              <Row gutter={[24, 0]}>
                <Col xs={24} md={8}>
                  <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }} >
                    <Form form={form} layout="vertical" onFinish={handleSubmit}>
                      <Form.Item
                        name="firstName"
                        label="First Name"
                        rules={[{ required: true, message: 'Please enter your first name' }]}
                        style={formItemStyle}
                      >
                        <Input style={inputStyle} />
                      </Form.Item>
                      <Form.Item
                        name="lastName"
                        label="Last Name"
                        rules={[{ required: true, message: 'Please enter your last name' }]}
                        style={formItemStyle}
                      >
                        <Input style={inputStyle} />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        label="Email"
                        rules={[{ required: true, message: 'Please enter your email' }]}
                        style={formItemStyle}
                      >
                        <Input type="email" style={inputStyle} />
                      </Form.Item>
                      {!showPasswordFields && (
                        <Form.Item>
                            <button
                            onClick={() => { setShowPasswordFields(true) } } 
                            style={{ background: 'none', border: 'none', color: 'blue', cursor: 'pointer' }}
                            >
                            Update password?
                            </button>
                        </Form.Item>
                        )}
                        {showPasswordFields && (
                        <div className="inline-form-items" >
                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[{ }]}
                                style={formItemStyle}
                            >
                                <Input.Password className="rounded-input" style={inputStyle}/>
                            </Form.Item>
                            <Form.Item
                                name="confirmPassword"
                                label="Confirm Password"
                                rules={[{ },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords do not match!'));
                                    },
                                }),
                                ]}
                                style={formItemStyle}
                            >
                                <Input.Password className="rounded-input" style={inputStyle}/>
                            </Form.Item>
                        </div>
                        )}
                      <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                          Save Changes
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </Col>
                <Col xs={24} md={16}>
                  <h6 className="font-semibold">Subscription Management</h6>
                  <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
                  {isRootUser ? (
                    <>
                        <button
                          type="button"
                          onClick={handleManageAccount}
                          style={{
                            background: 'none',
                            border: 'none',
                            color: 'blue',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            padding: 0,
                            fontSize: 'inherit',
                            fontFamily: 'inherit',
                          }}
                        >
                          Manage account
                        </button>
                      </>
                    ) : (
                      <p>Please contact account owner to manage subscription</p>
                    )}  
                  </div>
                </Col>
              </Row>
            </Card>
          </Main>
        </AppLayout>
      </>
    );
  }