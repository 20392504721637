/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useCallback, useEffect, useState } from "react";

import {
  Card,
  Col,
  Row,
  message,
  Dropdown,
  Menu,
  Table,
  Modal,
  Spin,
  Button,
  Select,
  Space,
  Checkbox,
  Input,
} from "antd";
import {
  EllipsisOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import Main from "../components/layout/Main";
import axios from "axios";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { useNavigate } from "react-router-dom";

const { confirm } = Modal;

interface Assessment {
  id: number;
  name: string
  question: string;
  status: string;
  createdAt: string;
  incidentDate: string;
  type: string;
  large: boolean;
  thirdParty: boolean;
}

const Assessments: React.FC = () => {
  const [assessmentData, setAssessmentData] = useState<Assessment[]>([]);
  const [assessmentDetailData, setAssessmentDetailData] = useState<any[]>([]);
  const [selectedAssessmentStatus, setSelectedAssessmentStatus] = useState<string>(''); 
  const [selectedRowKey, setSelectedRowKey] = useState<React.Key>(0);
  const [loading, setLoading] = useState(true); 
  const [isModalVisible, setIsModalVisible] = useState(false); 
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [currentAssessment, setCurrentAssessment] = useState<Assessment | null>(null); 
  const [currentAssessmentName, setCurrentAssessmentName] = useState<string>('');
  const [showArchived, setShowArchived] = useState(false); 
  const [newAssessmentName, setNewAssessmentName] = useState<string>(''); 
  const token = localStorage.getItem('jwtToken');
  const navigate = useNavigate();
  const filteredAssessmentData = showArchived
  ? assessmentData
  : assessmentData.filter((assessment) => assessment.status !== 'archived');

  const openChatWithQuestion = (question: string) => {
    console.log('Opening chat with question:', question); 
    navigate('/', { state: { question } });
  };
  
  const answerOptions = [
    { label: 'Met', value: 'met', emoji: '✅' },
    { label: 'Unmet', value: 'unmet', emoji: '❌' },
    { label: 'N/A', value: 'na', emoji: '🚫'  },
    { label: 'Not Answered', value: 'not_answered', emoji: '❔'  },
  ];

  const riskOptions = [
    { label: 'High', value: 'high', emoji: '🔴' },
    { label: 'Medium', value: 'medium', emoji: '🟡' },
    { label: 'Low', value: 'low', emoji: '🟢' },
  ];

  const assessmentColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: any) => (
        <div style={{  }}>
          {text}
        </div>
      ),
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      //width: '400px',
      render: (text: any) => (
        <div style={{  }}>
          {text}
        </div>
      ),
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'vesrion',
      render: (text: any) => (
        <div style={{  }}>
          {text}
        </div>
      ),
    },
    {
      title: 'Started',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: string) =>  date ? moment(date).format('MM/DD/YYYY') : ' ', // Format the date
    },
    {
      title: 'Completed',
      dataIndex: 'finishedAt',
      key: 'finishedAt',
      render: (date: string) =>  date ? moment(date).format('MM/DD/YYYY') : ' ', // Format the date
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (type: string) => {
        let emoji;
        switch (type.toLowerCase()) {
          case 'open':
            emoji = '🟢';
            break;
          case 'completed':
            emoji = '✅';
            break;
          case 'archived':
            emoji = '📦';
            break;
          default:
            emoji = '';
        }
        return (
          <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
            {emoji} {type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()} {/* Capitalize the first letter */}
          </div>
        );
      },
    }, 
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
      render: (_: any, record: any) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="editName" onClick={() => showEditModal(record)}>Edit Name</Menu.Item>
              <Menu.Item key="complete" style={{ color: 'black' }} onClick={() => showCompleteConfirm(record.id)}>Complete</Menu.Item>
              <Menu.Divider />            
              <Menu.Item key="archive" style={{ color: 'red' }} onClick={() => showArchiveConfirm(record.id)}>Archive</Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <EllipsisOutlined style={{ cursor: 'pointer' }} />
        </Dropdown>
      ),
    }
  ];

  const assessmentDetailColumns = [
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
      width: '200px',
      render: (text: any) => (
        <div style={{  }}>
          {text}
        </div>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (text: any) => (
        <div style={{  }}>
          {text} 
        </div>
      ),
    },
    {
      title: 'Risk',
      dataIndex: 'answer',
      key: 'risk',
      render: (text: any, record: any) => {
        return (
          <Select
            value={record?.answer?.risk || 'medium'} // Default to 'medium' if record.answer.risk does not exist
            onChange={(value) => handleAnswerChange(value, record)}
            style={{ width: '150px'}}
            disabled={selectedAssessmentStatus !== 'open'} // Disable if status is not Open
            options={riskOptions.map(option => ({
              label: (
                <Space>
                  <span role="img" aria-label={option.label}>
                    {option.emoji}
                  </span>
                  {option.label}
                </Space>
              ),
              value: option.value,
            }))}
          />
        );
      },
    },
    {
      title: 'Answer',
      dataIndex: 'answer',
      key: 'response',
      render: (text: any, record: any) => {
        return (
          <Select
            value={record?.answer?.response || 'not_answered'} 
            onChange={(value) => handleAnswerChange(value, record)}
            style={{ width: '150px'}}
            disabled={selectedAssessmentStatus !== 'open'} // Disable if status is not Open
            options={answerOptions.map(option => ({
              label: (
                <Space>
                  <span role="img" aria-label={option.label}>
                    {option.emoji}
                  </span>
                  {option.label}
                </Space>
              ),
              value: option.value,
            }))}
          />
        );
      },
    },
    {
      title: 'Updated',
      dataIndex: ['answer', 'updatedAt'], // Reference answer.updatedAt
      key: 'updatedAt',
      render: (date: string) => moment(date).format('MM/DD/YYYY'), 
    },
    {
      title: 'Help',
      key: 'help',
      width: '10%',
      render: (_: any, record: any) => (
        <Button 
          type="link" 
          onClick={() => openChatWithQuestion(`Tell me more about HIPAA assessment question "${record.question}"`)}
        >
          <QuestionCircleOutlined />
        </Button>
      ),
    }
  ];

  const handleAnswerChange = async (value: string, record: any) => {
    try {

      if (!token) {
        throw new Error('Token not found');
      }

      const response = await axios.put(`${process.env.REACT_APP_API_DOMAIN}/assessment/answer/${record.answer.id}`, {
        value, 
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update the assessment data
      const updatedAssessmentData = assessmentDetailData.map((item: any) => {
        if (item.id === record.id) {
          return {
            ...item,
            answer: {
              ...item.answer,
              response: response.data.response,
              risk: response.data.risk,
            },
          };
        }
        return item;
      });

      setAssessmentDetailData(updatedAssessmentData);

    } catch (error) { 
      message.error('Error updating answer, please try again');
    } 
  }

  const fetchAssessmentDetails = useCallback(async (id: string | number, name: string) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/assessment/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCurrentAssessmentName(name);
      setAssessmentDetailData(response.data);
    } catch (error) {
      message.error('Error fetching assessment details');
    }
  }, [token]);

  const fetchAssessments = useCallback(async () => {
    try {
      if (!token) {
        throw new Error('Token not found');
       }

      const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/assessment`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const assessments = response.data.map((assessment: any, index: any) => ({
        id: assessment.id,
        name: assessment.name,
        createdAt: assessment.createdAt,
        finishedAt: assessment.finishedAt,
        version: assessment.assessmentTemplate.version, 
        year: assessment.assessmentTemplate.year, 
        status: assessment.status,
      }));
      setAssessmentData(assessments);

      if (response.data.length > 0) {
        const firstAssessmentId = response.data[0].id;
        const firstAssessmentStatus = response.data[0].status;
        const firstAssessmentName = response.data[0].name;
        setSelectedRowKey(firstAssessmentId); 
        setSelectedAssessmentStatus(firstAssessmentStatus); 
        fetchAssessmentDetails(firstAssessmentId, firstAssessmentName);      
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    finally {
      setLoading(false);
    } 
  },[token, fetchAssessmentDetails]);
  
  const rowAssessmentSelection = {
    type: 'radio' as 'radio', // Allow only one row to be selected at a time
    selectedRowKeys: [selectedRowKey],
    columnTitle: '',
  };

  useEffect(() => { 
    fetchAssessments();
  }, [fetchAssessments]);

  const showArchiveConfirm = (id: string) => {
    console.log('userId:', id);
    confirm({
      title: 'Are you sure you to archive this assessment?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await axios.put(`${process.env.REACT_APP_API_DOMAIN}/assessment/${id}/archived`, {}, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          message.success('Assessment archived successfully');
          fetchAssessments(); 
        } catch (error) {
          message.error('Error deleting user:');
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const showCompleteConfirm = (id: string) => {
    console.log('userId:', id);
    confirm({
      title: 'Are you sure you to complete this assessment?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await axios.put(`${process.env.REACT_APP_API_DOMAIN}/assessment/${id}/completed`, {}, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          message.success('Assessment archived successfully');
          fetchAssessments(); 
        } catch (error) {
          message.error('Error deleting user:');
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_DOMAIN}/assessment/`, {
        name: newAssessmentName,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      message.success('New assessment started');
      fetchAssessments();
    } catch (error) {
      message.error('Error starting new assessment');
    } finally {
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleShowArchivedChange = (e: CheckboxChangeEvent) => {
    setShowArchived(e.target.checked);
  };

  const showEditModal = (record: Assessment) => {
    setCurrentAssessment(record);
    setNewAssessmentName(record.name);
    setIsEditModalVisible(true);
  };

  const handleEditOk = async () => {
    try {
      if (!currentAssessment) return;

      await axios.put(`${process.env.REACT_APP_API_DOMAIN}/assessment/${currentAssessment.id}`, {
        name: newAssessmentName, // Send the updated assessment name
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      message.success('Assessment name updated successfully');
      fetchAssessments(); // Refresh the assessment data
    } catch (error) {
      message.error('Error updating assessment name');
    } finally {
      setIsEditModalVisible(false);
      setNewAssessmentName(''); // Clear the input field
      setCurrentAssessment(null); // Clear the current assessment
    }
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
    setNewAssessmentName(''); // Clear the input field
    setCurrentAssessment(null); // Clear the current assessment
  };

  return (
    <>
      <Main>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Title level={4} style={{ margin: 0 }}>Assessments</Title>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                  <Checkbox
                    style={{ marginLeft: '16px' }}
                    checked={showArchived}
                    onChange={handleShowArchivedChange}
                  >
                    Show Archived
                  </Checkbox>
                  <Button type="primary" onClick={showModal}>Start Assessment</Button>
                </div>
              </div>
              }
            >
              <div className="table-responsive">
              {loading ? (
                <div className="loading-container">
                  <Spin size="large" /> {/* Render the loading indicator when loadingTemplates is true */}
                </div>
              ) : (
                <Table
                  columns={assessmentColumns}
                  dataSource={filteredAssessmentData.slice(0, 25)}
                  rowSelection={rowAssessmentSelection}
                  pagination={false} 
                  rowKey="id"
                  onRow={(record) => ({
                    onClick: () => {
                      setSelectedRowKey(record.id);
                      setSelectedAssessmentStatus(record.status); // Set the selected assessment status
                      fetchAssessmentDetails(record.id, record.name);
                    },
                  })}
                />
              )}
              </div>
            </Card>
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Title level={4} style={{ margin: 0 }}>Questions | {currentAssessmentName}</Title>
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                  </div>
                </div>
              }
            >
              <div className="table-responsive">
              {loading ? (
                <div className="loading-container">
                  <Spin size="large" /> {/* Render the loading indicator when loadingTemplates is true */}
                </div>
              ) : (
                <Table
                  columns={assessmentDetailColumns}
                  dataSource={assessmentDetailData}
                  rowKey="id"
                />
              )}
              </div>
            </Card>
            </Col>
          </Row>
        </div>
      </Main>
      <Modal
        title="Start New Assessment"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>Please enter the assessment name</p>
        <Input
          value={newAssessmentName}
          onChange={(e) => setNewAssessmentName(e.target.value)}
          placeholder="Security Risk Assessment 2025"
        />
      </Modal>
      <Modal
        title="Edit Assessment Name"
        open={isEditModalVisible}
        onOk={handleEditOk}
        onCancel={handleEditCancel}
        okText="Save"
        cancelText="Cancel"
      >
        <p>Edit the name of the assessment:</p>
        <Input
          value={newAssessmentName}
          onChange={(e) => setNewAssessmentName(e.target.value)}
          placeholder="Assessment Name"
        />
      </Modal>
    </>
  );
  
}

export default Assessments;