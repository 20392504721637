import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Card, Divider, Tag, Typography } from 'antd';
import Link from 'antd/lib/typography/Link';
import '../assets/styles/badge.css';  
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { Title, Text } = Typography; // Destructure Title and Text from Typography

interface BadgeInfo {
  name: string;
  status: string;
  createdAt: string;
}

export default function BadgePage() {
  const { orgId } = useParams();
  const [badgeInfo, setBadgeInfo] = useState<BadgeInfo | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBadgeInfo = async () => {
      try {

        if (!orgId) {
          throw new Error('Organization ID is required');
        }
        
        const decodedURLString = decodeURIComponent(orgId);
        const decryptOrgId = atob(decodedURLString);
        const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/badge/${decryptOrgId}`, {});
        setBadgeInfo(response.data);
      } catch (err) {
        setError('Unable to load badge information');
        console.error(err);
      }
    };

    fetchBadgeInfo();
  }, [orgId]);

  if (error) {
    return <div className="text-center text-red-600">{error}</div>;
  }

  if (!badgeInfo) {
    return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
  }

  const createdYear = new Date(badgeInfo.createdAt).getFullYear();

  return (
    <div className="badge-container">
      <Card className="badge-card">
        {/* Header Section */}
        <div className="badge-header">
          <img
            src="/hipaa-badge.svg"
            alt="HIPAA Badge"
            className="badge-logo"
          />
          <div className="badge-title-container">
            <Title level={2} className="mb-2">{badgeInfo.name}</Title>
            <Text className="badge-subtitle">
              HIPAA Compliance verified by <Text strong className="verified-by">MedComply.ai</Text>
            </Text>
            <div className="badge-tag-container">
              <Tag color="success" className="status-tag">
                ● Actively Verified
              </Tag>
            </div>
          </div>
        </div>

        {/* Verification Info */}
        <div className="verification-grid">
          <Card className="verification-card">
            <div className="verification-label">Member Since</div>
            <div className="verification-value">{createdYear}</div>
          </Card>
          <Card className="verification-card">
            <div className="verification-label">Last Verified</div>
            <div className="verification-value">Today</div>
          </Card>
        </div>

        <Divider />

        {/* Info Section */}
        <div className="info-section">
          <Title level={3} className="mb-4">Why HIPAA Compliance Matters</Title>
          <Text className="info-text">
            Ensuring HIPAA compliance is crucial for protecting patient privacy and 
            securing sensitive health information. By using MedComply.ai, {badgeInfo.name} demonstrates a strong commitment to maintaining the highest standards of 
            data protection and regulatory compliance.
          </Text>
          <Text className="info-text">
            For more information about our compliance practices, visit{' '}
            <Link href="https://medcomply.ai">MedComply.ai</Link>
          </Text>
        </div>

      <Divider />

        {/* Incident Reporting */}
        <div className="incident-section">
          <Button 
            type="text"
            danger
            href={`/incident/${orgId}`}
          >
            <ExclamationCircleOutlined style={{ color: 'red' }} />
            Report an Incident
          </Button>
        </div>
      </Card>
    </div>
  );
} 